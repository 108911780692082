* {
    box-sizing: content-box;
    /* or border-box if needed */
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    /* Wrap columns for responsiveness */
    justify-content: space-between;
    align-items: baseline;
}

.form-column {
    flex: 1;
    /* Equal flex grow for each column */
    min-width: 250px;
    /* Minimum width of a column */
    max-width: calc(33% - 20px);
    /* Adjust max width to allow for margins */
    margin-bottom: 20px;
    /* Spacing between columns when wrapped */
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.warning-msg {
    color: red;
    font-size: 13px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"],
.form-group input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
    }

    .form-column {
        max-width: 100%;
    }
}


button {
    background-color: #53cbfa;
    color: white;
    padding: 10px 50px;
    /* Increased padding to make the button bigger */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: auto;
    /* Keep width to auto to size with the content */
    font-size: 0.9em;
    /* Adjust font size if necessary */
    margin-top: 20px;
    /* To push the button below the columns */
    margin-left: 0;
    /* Align the button to the left */
    margin-right: auto;
    /* Maintain automatic margin on the right for proper alignment */
}

.input-group {
    display: flex;
    align-items: center;
}