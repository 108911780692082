/* UserProfileView.css */
* {
    box-sizing: border-box;
    /* or border-box if needed */
}

.user-profile-view {
    font-family: 'Arial', sans-serif;
    /* color: #333; */
    /* background-color: #f7f7f7; */
    padding: 20px 150px 20px 150px;
    /* max-width: 800px; */
    /* margin: 20px auto; */
    /* border-radius: 8px; */
}

.action-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    margin: 5px;
}

.deactivate {
    background-color: #dc3545;
    color: #fff;
}

.client-logo {
    width: 150px;
    height: 150px;
}

.cancel-confirm {
    display: flex;
    flex-direction: row;
}

/* Add more styles here as needed */