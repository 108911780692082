* {
  box-sizing: content-box;
  /* or border-box if needed */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}


#root {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}