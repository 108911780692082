.left-bottom {
    position: absolute;
    bottom: 10px;
    width: 95%;
    text-align: center;
    margin-top: 20px;
}

.left-bottom-text {
    color: #8a8888;
    font-size: 15px;
}

.subtext {
    color: #807e7e;
}

.back-btn {
    display: flex;
    /* flex: 1; */
    width: 100%;
    padding-bottom: 40px;
    padding-left: 80px;
    /* align-items: start; */
    /* justify-content: flex-start; */
}

.form-group {
    margin-bottom: 20px;
    width: 100%;
}

.form-area {
    width: 50%;
    padding: 20px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.logo-img {
    width: 150px;
    height: 120px;
    margin-bottom: 20px;
}

.forgot-password {
    text-align: right;
    margin-top: -5px;
    margin-bottom: 15px;
    font-size: 14px;
}

.forgot-password a {
    text-decoration: none;
    color: #53cbfa;
}

.contact-us a {
    text-decoration: none;
    color: #53cbfa;
}

.contact-us-text {
    text-align: left;
    margin-top: 20px;
    color: #807e7e;
    font-size: 15px;
}

.cancel-confirm {
    display: flex;
    flex-direction: row;
}

input[type="number"].otp-input-field {
    -moz-appearance: textfield;
    appearance: none;
}


input[type="number"].otp-input-field::-webkit-inner-spin-button,
input[type="number"].otp-input-field::-webkit-outer-spin-button {
    -webkit-appearance: none;
}