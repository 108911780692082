.upload-folder-container {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    width: 80%;
    height: 100%;
    padding: 15px;
    border: 1px grey dashed;
    border-radius: 10px;
    background-color: #e9fbfb;
    text-align: center;
}