.dashboard {
    font-family: Arial, sans-serif;
    display: flex;
    height: 100vh;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px 5px 0;
    background-color: #0c222a;
    height: 78px;
    width: 100%;
    align-items: center;
    /* Other styles for the header */
}

.action-icons {
    padding-right: 25px;
    padding-left: 10px;
    display: inline-flex;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 70px;
}

.copyright-text {
    color: #71D0F6;
    font-size: 11px;
    margin: 5px;
}

.search-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    padding: 0px 10px;
    width: 50%;
}

.search-icon {
    color: #5e5d5d;
    margin-right: 8px;
}

.search-container input {
    border: none;
    outline: none;
    font-size: 16px;
    padding-top: 17px;
}

.user-profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 70px;
}

.profile-icon {
    font-size: 50px;
    color: #333;
}

/* Navigation */

.nav-links {
    list-style: none;
    display: inline-flex;
    align-items: center;
    color: #5e5d5d;
    /* Align to the start of the nav bar */
}

.spacer {
    flex-grow: 1;
}

/* Adjust the styles as needed to match the design from the image */

/* Additional responsive or aesthetic styling */

/* You can add more CSS here to style the page as per the design */


.name-img {
    width: 180px
}


/* Existing CSS... */

/* Sidebar Styles */
.dashboard-sidebar {
    background-color: #0c222a;
    /* Match the background color to the image */
    color: #ffffff;
    width: 250px;
    /* Adjust width as needed */
    padding: 20px;
    height: 100vh;
    /* Full-height */
}

.dashboard-sidebar h1 {
    margin-bottom: 20px;
    font-size: 24px;
    /* Adjust size as needed */
}

.storage-space {
    margin-bottom: 20px;
}

.storage-bar {
    background-color: #ffffff;
    border-radius: 10px;
    height: 20px;
    /* Adjust height as needed */
    position: relative;
}

.storage-used {
    background-color: #008080;
    /* Adjust color as needed */
    border-radius: 10px 0 0 10px;
    height: 100%;
    width: 30%;
    /* Adjust based on used storage */
    display: block;
    position: absolute;
}

.storage-total {
    position: absolute;
    right: 0;
    padding: 0 10px;
}

.new-files-button {
    background-color: #008080;
    /* Adjust color as needed */
    color: #ffffff;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.sidebar-footer {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
}

/* Adjust the styles as needed to match the design from the image */

/* Additional responsive or aesthetic styling */

/* You can add more CSS here to style the page as per the design */

/* Dashboard Flex Container */
.dashboard-flex-container {
    /* display: flex; */
    /* height: 100vh; */
    flex-grow: 1;
}

/* Existing sidebar styles... */

/* Main Content Styles */
.main-content {
    flex-grow: 1;

    height: 100vh;
    /* This will make the main content take up the remaining space */
    /* Add padding, margins, or other styles as needed */
}

/* Existing main content and other styles... */

/* Ensure the rest of the CSS for the main content and other elements is included here */