* {
    box-sizing: border-box;
    /* or border-box if needed */
}

.drive-card-grid {
    padding: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 40px;
}

.dashboard-content {
    padding: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.add-button {
    position: fixed;
    /* Fixed position relative to the viewport */
    bottom: 40px;
    /* 20px from the bottom */
    right: 40px;
    /* 20px from the right */

}

.empty-folder {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 100px;
    vertical-align: middle;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    /* Adjust the width as needed */
    display: block;
    /* You can also use 'inline-block' */
}