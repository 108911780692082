* {
    box-sizing: content-box;
    /* or border-box if needed */
}

.heading {
    display: flex;
    padding-bottom: 20px;
    padding-left: 12px;
    color: #5e5d5d;
    font-size: 30px;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    /* Wrap columns for responsiveness */
    /* justify-content: space-around; */
}

.form-column {
    flex: 1;
    /* Equal flex grow for each column */
    min-width: 250px;
    /* Minimum width of a column */
    /* max-width: calc(33% - 20px); */
    /* Adjust max width to allow for margins */
    margin-bottom: 20px;
    /* Spacing between columns when wrapped */
    justify-content: space-between;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 200;
    font-size: 13px;
    color: #5e5d5d;
}

.warning-msg {
    color: red;
    font-size: 13px;
}

.form-group input[type="text"],
.form-group select,
.form-group input[type="email"],
.form-group input[type="password"],
.form-group input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    background-color: #53cbfa;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 20px;
    /* To push the button below the columns */
}

@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
    }

    .form-column {
        max-width: 100%;
    }
}

.input-group {
    display: flex;
    align-items: center;
}

.add-ip-button {
    border: none;
    font-size: 1.2em;
    cursor: pointer;
    padding-left: 10px;
    margin-left: 10px;
}

table,
tr,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
    /* table-layout: fixed; */
    /* width: 60px; */
    overflow: hidden;
    word-wrap: break-word;
    min-width: 20px;
}