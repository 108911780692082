.client-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 40px;
    padding: 30px;
    padding-left: 40px;
    padding-right: 40px;
    /* Adjust the minmax values to suit the desired card size */
}

.client-list {
    display: list-item;
    padding: 30px;
}

.client-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
}

.client-avatar {
    padding: 10px;
}

.client-card.add-client {
    background-color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    color: #333;
}

.client-name {
    padding-top: 10px;
    font-weight: bold;
}

.client-members {
    color: #666;
    font-size: 13px;
    display: flex;
    align-items: center;
}

/* Adjust the styles as needed to match the design from the image */

/* Additional responsive or aesthetic styling */

/* You can add more CSS here to style the page as per the design */

.logo-img {
    width: 150px;
    height: 120px;
    margin-bottom: 20px;
}

.empty-folder {
    display: flex;
    min-height: 70vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}