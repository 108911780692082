.container {
    display: flex;
    align-items: center;
    justify-items: center;
    flex: 1;
    width: 90%;
    height: 100%;
    padding: 20px;
    border: 1px grey dashed;
    border-radius: 10px;
    background-color: #e9fbfb;
    text-align: center;
}

.browse-btn {
    padding-top: 10px;
}