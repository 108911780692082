.dashboard {
    font-family: Arial, sans-serif;
    max-width: 100%;
    flex-direction: column;
}

/* Additional CSS for ClientInfo Header */
/* CSS for updated ClientInfo Header */

.client-info-header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
    padding: 0px 12px 0px 12px;
}

.client-info h3 {
    margin: 0;
    flex-grow: 1;
    /* Ensures the title takes up the available space pushing the button to the right */
}

.edit-button {
    padding: 5px 10px;
    /* Smaller padding for a smaller button */
    font-size: 0.8rem;
    /* Smaller font size for the text inside the button */
    /* background-color: #f5f5f5; */
    /* Matching the background or any desired color */
    /* border: 1px solid #ccc; */
    /* Subtle border color */
    /* border-radius: 5px; */
    /* Rounded corners for the button */
    cursor: pointer;
}

.edit-button:active,
.edit-button:focus {
    outline: none;
    /* Removes the outline to keep the design clean */
}

.client-details {
    display: flex;
    justify-content: space-around;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    align-items: center;
}

.details-section {
    display: inline-flex;
    padding: 10px;
    align-items: center;
    justify-content: space-evenly;
}

.client-logo {
    width: 150px;
    height: 150px;
}

.client-info p {
    margin: 5px 0;
}

.details-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* Additional CSS for StorageChart Container */

.storage-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.goto-drive-btn {
    /* margin-top: 20px; */
    /* padding: 20px 20px; */
    /* background-color: #4CAF50; */
    /* A green background for visibility */
    color: white;
    border: none;
    cursor: pointer;
    width: 150px;
    white-space: nowrap;
}

/* Ensure the button text is centered */
.goto-drive-btn:active,
.goto-drive-btn:focus {
    outline: none;
}

.details-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* CSS for updated MemberList Component */
.member-list-container {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 20px 30px;
}

.member-list-header {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 20px;
    padding: 10px 30px 10px 30px;
    gap: 20px;
}

.member-card {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    flex: 1 0 18%;
    /* Sets the basis for each card to 18% of the flex container's width */
    max-width: 15%;
    position: relative;
    /* Ensures that the card does not grow beyond 18% of the container's width */
    /* Additional styles may be necessary for padding, margins, etc. */
    /* border: 1px solid #ccc; */
    /* Adding a border */
    border-radius: 15px;
    /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    /* background-color: white; */
    /* Background color for the card */
}

.member-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit-btn {
    display: inline-flex;
    justify-content: flex-end;
    align-items: baseline;
}

.edit-icon {
    background: #53cbfa;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    /* Adjust size as needed */
    padding: 5px;
    position: relative;
}

.edit-icon:hover {
    color: #53cbfa;
    /* Change color on hover for visual feedback */
}

.member-name {
    padding-top: 5px;
    font-weight: bold;
    margin: 0%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 8vw;
}

.member-mail {
    color: #666;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 8vw;
}

.member-role {
    font-size: 13px;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 8vw;

}

.member-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 30px;
    padding-left: 30px;
}

.member-info {
    padding: 15px 0px 10px 0px;

}

.member-card-content {
    display: flex;
    flex-direction: column;
    padding: 10px 25px 0px 25px;
    align-items: center;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cancel-confirm {
    display: flex;
    flex-direction: row;
}

.cancel-button,
.confirm-button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-button {
    background-color: #f44336;
    color: white;
}

.confirm-button {
    background-color: #4caf50;
    color: white;
}

.circle-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 18px;
    padding-top: 10px;
}

.circle-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    /* Adjust the width of the circle */
    height: 100px;
    /* Adjust the height of the circle */
    border-radius: 50%;
    background-color: white;
    border: 1px solid #53cbfa;
    padding: 50px;
}

.larger-text {
    font-size: 22px;
    /* Adjust the font size of the larger text */
}

.smaller-text {
    font-size: 12px;
    /* Adjust the font size of the smaller text */
}